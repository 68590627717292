<template>
  <div :class="containerClass">
    <input
      :checked="isChecked"
      v-bind="attrs"
      type="checkbox"
      :disabled="disabled"
      @input="handleInput"
    >
    <slot name="label">
      <label v-if="label" :for="attrs.id || ''" class="ml-2">{{ label }}</label>
    </slot>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'BaseCheckbox',

    inheritAttrs: false,

    props: {
        modelValue: {
            type: [Boolean, String, Number, Array],
            default: false,
            description: 'bound value(if array is bound, checkbox values will be added to that array however ensure that value attribute is added to the checkboxes)'
        },
        label: {
            type: [String, Array],
            default: '',
            description: 'label for checkbox input'
        },
        uncheckedValue: {
            type: [String, Number, Boolean],
            default: undefined,
            description: 'returns value of unchecked checkbox(ignored for checkboxes bound to same array)'
        },
        containerClass: {
            type: [Object, Array, String],
            default: '',
            description: 'classes applied to root element'
        },
        disabled: {
            type: Boolean,
            default: false,
            description: 'disables checkbox if set to true'
        }
    },

    emits: [
        'update:modelValue'
    ],

    setup (props, { attrs, emit }) {
        // checkbox check/uncheck logic
        const isBoundValueArray = computed(() => {
            return Array.isArray(props.modelValue);
        });
        const isChecked = computed(() => {
            if (isBoundValueArray.value) {
                return props.modelValue.includes(attrs.value);
            } else {
                if (attrs.value && props.uncheckedValue !== undefined) {
                    return props.modelValue === attrs.value;
                } else {
                    return props.modelValue;
                }
            }
        });
        const handleInput = (event) => {
            let updatedValue = props.modelValue;
            if (isBoundValueArray.value) {
                // handle multiple checkbox bound to same array
                if (updatedValue.includes(attrs.value)) {
                    updatedValue.splice(updatedValue.indexOf(attrs.value), 1);
                } else {
                    updatedValue.push(attrs.value);
                }
            } else {
                // handle checkbox bound to different variables
                updatedValue = attrs.value && props.uncheckedValue !== undefined ? (event.target.checked ? attrs.value : props.uncheckedValue) : event.target.checked;
            }
            emit('update:modelValue', updatedValue);
        };

        return {
            isChecked,
            handleInput,
            attrs
        };
    }
};
</script>

<style scoped>
@layer components {
    /* checkbox states styling */
    input[type="checkbox"] {
        &:disabled {
            @apply opacity-50 cursor-not-allowed;
        }
    }
}
</style>
